{
  "tooltips": {
    "placeMarker": "کلیک برای جانمایی نشان",
    "firstVertex": "کلیک برای رسم اولین رأس",
    "continueLine": "کلیک برای ادامه رسم",
    "finishLine": "کلیک روی هر نشان موجود برای پایان",
    "finishPoly": "کلیک روی اولین نشان برای پایان",
    "finishRect": "کلیک برای پایان",
    "startCircle": "کلیک برای رسم مرکز دایره",
    "finishCircle": "کلیک برای پایان رسم دایره",
    "placeCircleMarker": "کلیک برای رسم نشان دایره",
    "placeText": "کلیک برای نوشتن متن"
  },
  "actions": {
    "finish": "پایان",
    "cancel": "لفو",
    "removeLastVertex": "حذف آخرین رأس"
  },
  "buttonTitles": {
    "drawMarkerButton": "درج نشان",
    "drawPolyButton": "رسم چندضلعی",
    "drawLineButton": "رسم خط",
    "drawCircleButton": "رسم دایره",
    "drawRectButton": "رسم چهارضلعی",
    "editButton": "ویرایش لایه‌ها",
    "dragButton": "جابجایی لایه‌ها",
    "cutButton": "برش لایه‌ها",
    "deleteButton": "حذف لایه‌ها",
    "drawCircleMarkerButton": "رسم نشان دایره",
    "snappingButton": "نشانگر را به لایه‌ها و رئوس دیگر بکشید",
    "pinningButton": "رئوس مشترک را با هم پین کنید",
    "rotateButton": "چرخش لایه",
    "drawTextButton": "رسم متن",
    "scaleButton": "مقیاس‌گذاری",
    "autoTracingButton": "ردیاب خودکار"
  },
  "measurements": {
    "totalLength": "طول",
    "segmentLength": "طول بخش",
    "area": "ناحیه",
    "radius": "شعاع",
    "perimeter": "محیط",
    "height": "ارتفاع",
    "width": "عرض",
    "coordinates": "موقعیت",
    "coordinatesMarker": "موقعیت نشان"
  }
}
