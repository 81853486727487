{
  "tooltips": {
    "placeMarker": "Klicka för att placera markör",
    "firstVertex": "Klicka för att placera första hörnet",
    "continueLine": "Klicka för att fortsätta rita",
    "finishLine": "Klicka på en existerande punkt för att slutföra",
    "finishPoly": "Klicka på den första punkten för att slutföra",
    "finishRect": "Klicka för att slutföra",
    "startCircle": "Klicka för att placera cirkelns centrum",
    "finishCircle": "Klicka för att slutföra cirkeln",
    "placeCircleMarker": "Klicka för att placera cirkelmarkör"
  },
  "actions": {
    "finish": "Slutför",
    "cancel": "Avbryt",
    "removeLastVertex": "Ta bort sista hörnet"
  },
  "buttonTitles": {
    "drawMarkerButton": "Rita Markör",
    "drawPolyButton": "Rita Polygoner",
    "drawLineButton": "Rita Linje",
    "drawCircleButton": "Rita Cirkel",
    "drawRectButton": "Rita Rektangel",
    "editButton": "Redigera Lager",
    "dragButton": "Dra Lager",
    "cutButton": "Klipp i Lager",
    "deleteButton": "Ta bort Lager",
    "drawCircleMarkerButton": "Rita Cirkelmarkör",
    "snappingButton": "Snäpp dra markören till andra lager och hörn",
    "pinningButton": "Fäst delade hörn tillsammans",
    "rotateButton": "Rotera lagret"
  }
}
