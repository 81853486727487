{
  "tooltips": {
    "placeMarker": "Presiona para colocar un marcador",
    "firstVertex": "Presiona para colocar el primer vértice",
    "continueLine": "Presiona para continuar dibujando",
    "finishLine": "Presiona cualquier marcador existente para finalizar",
    "finishPoly": "Presiona el primer marcador para finalizar",
    "finishRect": "Presiona para finalizar",
    "startCircle": "Presiona para colocar el centro del círculo",
    "finishCircle": "Presiona para finalizar el círculo",
    "placeCircleMarker": "Presiona para colocar un marcador de círculo"
  },
  "actions": {
    "finish": "Finalizar",
    "cancel": "Cancelar",
    "removeLastVertex": "Eliminar último vértice"
  },
  "buttonTitles": {
    "drawMarkerButton": "Dibujar Marcador",
    "drawPolyButton": "Dibujar Polígono",
    "drawLineButton": "Dibujar Línea",
    "drawCircleButton": "Dibujar Círculo",
    "drawRectButton": "Dibujar Rectángulo",
    "editButton": "Editar Capas",
    "dragButton": "Arrastrar Capas",
    "cutButton": "Cortar Capas",
    "deleteButton": "Eliminar Capas",
    "drawCircleMarkerButton": "Dibujar Marcador de Círculo",
    "snappingButton": "El marcador de Snap arrastrado a otras capas y vértices",
    "pinningButton": "Fijar juntos los vértices compartidos",
    "rotateButton": "Rotar capa"
  }
}
